import { type StatisticClickType } from '~/types/statistic'

export const trackClick = async (
	eventDateId: string,
	type: StatisticClickType,
) => {
	const formData = new FormData()
	formData.append('eventDateId', eventDateId)
	formData.append('type', type)
	fetch('/api/statistics/click', { method: 'POST', body: formData })
}
